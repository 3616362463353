<template>
  <ion-page><cyan-page-wrapper
    :title="title"
    nav1="PARTIDO"
    :nav2="tipoConsejo"
    :ambito="ambito"
    :backRoute="backRoute"
  >
    <div class="mobileShow">
      <div class="banner nivel1">{{ tipoConsejo }}</div>
      <div class="banner nivel2" v-if="!esNacional">
        {{ ambito }}
      </div>
    </div>
    <div color="banner nodata" v-if="!datos.ok">
      {{ datos.reason }}
    </div>
    <div class="banner nodata" v-if="datos.ok && !datos.tieneCargos">
      No hay datos para los cargos de este {{ tipoDivision }}.
    </div>

    <ion-list v-if="datos.ok && datos.tieneCargos" lines="none">
      <party-entry v-for="v in datos.organicos" :key="v.id" :element="v" />
      <ion-item v-if="datos.coordinaciones.length">
        <div class="banner">COORDINACIONES OPERATIVAS</div>
      </ion-item>
      <party-entry v-for="v in datos.coordinaciones" :key="v.id" :element="v" />
    </ion-list>
  </cyan-page-wrapper></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, {
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";
import PartyEntry from "@/components/PartyEntry.vue";
import { divisiones, territorios } from "@/modules/cyanRegions";

import { IonList, IonItem,IonPage } from "@ionic/vue";

export default defineComponent({
  name: "PartyList",
  components: {
    IonItem,
    IonList,
    IonPage,
    CyanPageWrapper,
    PartyEntry,
  },
  data() {
    return {
    };
  },
  computed: {
    seed(): any {
          return (this as any).$route.params.seedPL || 'SV'
    },
    datos() {
      const s = (this as any).seed;
      if (!s || typeof store.state.partyLists[s] === "undefined")
        return { ok: false, reason: "" };
      return store.state.partyLists[s];
    },

    tipoConsejo() {
      const s = (this as any).seed;
      if (s == "") return "";
      if (s == "SV") return "CONSEJO NACIONAL";
      if (s.length <= 4) return "CONSEJO DEPARTAMENTAL";
      if (s.length <= 6) return "CONSEJO MUNICIPAL";
      if (s.length <= 8) return "CONSEJO DISTRITAL";
      return "EQUIPO DE ZONA";
    },

    tipoDivision() {
      const s = (this as any).seed;
      return s.length == 10 ? 'equipo' : 'consejo';
    },

    esNacional() {
      const s = (this as any).seed;
      return s.length <= 2;
    },

    backRoute() {
      const s = (this as any).seed;
      const u = store.state.userData.nivel || "";
      const lp = divisiones[s.length].largoPadre;

      if (!lp || u.length >= lp) {
        return '/partyLevels';
      } else {
        return '/partyPicker/' + s.length + '/' + s.substring(0,lp);
      }

    },

    title() {
      const _t = this as any;

      return _t.tipoConsejo;

      /*
      if (_t.seed == "SV") return "CONSEJO NACIONAL";
      return (_t.tipoConsejo + " " + _t.ambito.toUpperCase()).replace('ZONA ZONA', 'ZONA');
      */
    },

    ambito() {
      const s = (this as any).seed;

      if (! (s in territorios)) return "";

      const m = territorios[s];

      if (m.nombrePadre != "" && m.nombrePadre != m.nombre)
        return m.nombre + ", " + m.nombrePadre;
      else return m.nombre;
    },
  },
  methods: {},
});
</script>
<style scoped>
ion-list,
ion-item {
  background: transparent;
  --background: transparent;
}
</style>